// available channel ids, battery_voltage is excluded, keep in sync with django
// group them by device id

export const ALL_EXISTING_CHANNEL_IDS = [
	'temperature', 'humidity', 'pressure', 
	'pressure_1', 'pressure_2',
	'pressure_3', 'pressure_4',
	'temperature_1', 'temperature_2',
	'temperature_3', 'temperature_4',
	 'object_temperature', 'dew_point', 'dew_point_delta'
]
// mapping the different device models and the available channels
export const AVAILABLE_CHANNELS = {
	"1.2": [
		'temperature', 'humidity', 'object_temperature', 
		'dew_point', 'dew_point_delta'
	],
	"1.3": [
		'temperature', 'humidity', 'pressure',
		'object_temperature', 'dew_point', 'dew_point_delta'
	],
	"2.2": [
		'pressure_1', 'pressure_2', 'temperature_1', 'temperature_2'
	],
	"2.4": [
		'pressure_1', 'pressure_2', 'pressure_3', 'pressure_4',
		'temperature_1', 'temperature_2', 'temperature_3', 'temperature_4'
	]
}

export const CHANNEL_ID_SENSOR_MAPPING = {}


// default chart config values
export const CHART_LINE_WIDTH_BIG = 1;
export const CHART_POINT_SIZE_BIG = 1;

export const CHART_LINE_WIDTH_SMALL = 1;
export const CHART_POINT_SIZE_SMALL = 0;

export const HUMIDITY_COLOR = 'rgb(174, 198, 207)';
export const HUMIDITY_BACKGROUND_COLOR = 'rgba(174, 198, 207, 0.1)';
export const TEMPERATURE_COLOR = 'rgb(97, 205, 187)';
export const TEMPERATURE_BACKGROUND_COLOR = 'rgba(97, 205, 187, 0.1)';
export const DEWPOINT_COLOR = 'rgb(194, 97, 205)';
export const DEWPOINT_BACKGROUND_COLOR = 'rgb(194, 97, 205, 0.1)';
export const PRESSURE_COLOR = 'rgb(225, 124, 124)';
export const PRESSURE_BACKGROUND_COLOR = 'rgb(225, 124, 124, 0.1)';

// new chartjs styles

export const CHART_TITLE_BIG = {
	display: true,
	text: '<REPLACED>'
}

export const CHART_Y_SCALE_BIG = {
	suggestedMin: 0, // replaced
	suggestedMax: 0, // replaced
	title: {}
}

export const CHART_X_SCALE_BIG = {
	suggestedMin: 0, // replaced
	suggestedMax: 0, // replaced
	type: 'time',
	adapters: {
		date: {
			locale: undefined // replaced -> de, or other locale
		},
	},
	time: {
		unit: 'week',
		tooltipFormat: 'dd.MM.yyyy HH:mm:ss'
	},
	ticks :{
		source: 'data',
		maxRotation: 45,
		minRotation: 45
	},
	title: {}
}

export const CHART_ANNOTATION = {
	common: {
		adjustScaleRange: false,
	},
	annotations: []
}

export const CHART_ANNOTATION_LABEL = {
	display: true,
	content: 'Alarm für bla',
	position: 'end',
	backgroundColor: '#ffe69c',
	color: '#000',
	//backgroundColor: 'rgb(255, 230, 156)',
}

export const CHART_ANNOTATIONS = {
	id: '<REPLACED>',
	type: 'line',
	scaleID: 'y',
	value: 0, // replaced
	borderColor: '#ffc107',
	borderWidth: 1,
	adjustScaleRange: false,
	display: true,
	label: {} // replaced 
}

export const CHART_ZOOM_PLUGIN = {
	wheel: {
		enabled: false,
	},
	pinch: {
		enabled: true,
	},
	drag: {
		enabled: true,
	},
	mode: 'x',
	onZoomComplete: null,
}

export const CHART_PLUGINS_BIG = {
	decimation: {
		enabled: true,
		algorithm: 'min-max',
	},
	legend: {
		display: false,
	},
	title: {
		display: false,
	},
	tooltip: {
       	mode: 'nearest',
       	intersect: true
	},
	zoom: {
		zoom: {} // replaced
	},
	annotation: {}
}

export const CHART_PLUGINS_SMALL = {
	legend: {
		display: false,
	},
	title: {
		display: false,
	}
}

export const CHART_Y_SCALE_SMALL = {
	suggestedMin: 0, // replaced
	suggestedMax: 0, // replaced
	title: {
		display: false,
	}
}

export const CHART_X_SCALE_SMALL = {
	suggestedMin: 0, // replaced
	suggestedMax: 0, // replaced
	type: 'time',
	adapters: {
		date: {
			locale: undefined // replaced -> de, or other locale
		},
	},
	time: {
		unit: 'week',
		tooltipFormat: 'dd.MM.yyyy HH:mm:ss'
	},
	ticks :{
		source: 'data'
	},
	title: {
		display: false,
	}
}

export const CHART_OPTIONS_BIG = {
	responsive: true,
	pointRadius: CHART_POINT_SIZE_BIG,
	borderWidth: 1,
	maintainAspectRatio: false,
	animation: false,
	parsing: false,
	plugins: {}, // replaced
	scales: {} // replaced
}

export const CHART_CONFIG_BIG = {
	options: {},
	datasets: []
}

export const CHART_OPTIONS_SMALL = {
	responsive: true,
	pointRadius: CHART_POINT_SIZE_SMALL,
	borderWidth: 1,
	maintainAspectRatio: false,
	animation: false,
	parsing: false,
	plugins: CHART_PLUGINS_SMALL,
	scales: {}
}

export const CHART_CONFIG_SMALL = {
	options: {},
	datasets: []
}

export const CHART_CHANNEL_DATASET_CONFIG = {
	'temperature': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'humidity': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: HUMIDITY_COLOR,
		backgroundColor: HUMIDITY_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'pressure': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: PRESSURE_COLOR,
		backgroundColor: PRESSURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'pressure_1': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: PRESSURE_COLOR,
		backgroundColor: PRESSURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'temperature_1': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'pressure_2': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: PRESSURE_COLOR,
		backgroundColor: PRESSURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'temperature_2': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'pressure_3': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: PRESSURE_COLOR,
		backgroundColor: PRESSURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'temperature_3': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'pressure_4': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: PRESSURE_COLOR,
		backgroundColor: PRESSURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'temperature_4': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'object_temperature': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: TEMPERATURE_COLOR,
		backgroundColor: TEMPERATURE_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'dew_point': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: DEWPOINT_COLOR,
		backgroundColor: DEWPOINT_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	},
	'dew_point_delta': {
		label: '<REPLACED>', // replaced
		data: [], // replaced
		borderColor: DEWPOINT_COLOR,
		backgroundColor: DEWPOINT_BACKGROUND_COLOR,
		fill: 'origin',
		spanGaps: false
	}
}

// action prefix for our app
export const IOT_APP_PREFIX = 'IOT_APP_PREFIX'
export const WEBSOCKET_PREFIX = 'REDUX_WEBSOCKET'

