import React from 'react';
import Badge from 'react-bootstrap/Badge'
import {MoonStarsFill} from 'react-bootstrap-icons'

const NightModeIndicator = () => {
	return (
		<Badge bg='' className="blue-light-bg">
		<MoonStarsFill />
		</Badge>
	);
}

export default NightModeIndicator;
