import React, { forwardRef, useRef, useState, useEffect, useCallback } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import { useTranslation} from 'react-i18next';
import GroupPermissionWidget from '../widgets/GroupPermissionWidget'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Search} from 'react-bootstrap-icons'

const PermissionDnDContainer = forwardRef(({title, permissions, provided, with_search}, ref) =>  {
	const {t} = useTranslation();
	
	const search_input_ref = useRef(null)
	
	const [shown_permissions, set_shown_permissions] = useState(permissions)
	
	const on_search_change = useCallback((event) => {
		const new_shown_permissions = permissions.filter(function (permission) {
			if(search_input_ref.current.value !== '') {
				return permission.name.toLowerCase().includes(search_input_ref.current.value) || 
					   permission.content_type.app_label.toLowerCase().includes(search_input_ref.current.value) ||
					   `${permission.content_type.app_label.toLowerCase()} | ${permission.name.toLowerCase()}`.includes(search_input_ref.current.value)
			} else {
				return permissions
			}
		});
		set_shown_permissions(new_shown_permissions)
	}, [permissions])
	
	useEffect(() => {
		if(search_input_ref.current !== null && with_search) {
			on_search_change(undefined)
		} else { // for updates without search
			if(!with_search) {
				set_shown_permissions(permissions)
			}
		}
	}, [permissions, on_search_change, with_search])
	
	let search_field_jsx = []
	if(with_search) {
		search_field_jsx = [
			<InputGroup className="mb-3" key="search-field">
				<Button className="primary-light-bg"><Search /></Button>
				<Form.Control placeholder={t('Tippen um zu suchen')} ref={search_input_ref} onChange={on_search_change} />
			</InputGroup>
		]
	}
	
	let draggable_jsx = permissions.map(({ name, id, content_type }, index) => { // as every drag works with index, we have to hide elements that are not shown with display none
		let drag_class = 'default-setttings-container'
		if(provided.droppableProps["data-rbd-droppable-id"] === "1") {
			if(shown_permissions.some(e => e.id === id)) {
				drag_class = 'default-setttings-container'
			} else {
				drag_class = 'default-setttings-container display-none'
			}
		}
		return ( 
	    	<Draggable
				key={`permission-draggable-${id}`}
				draggableId={`permission-${id}`}
				index={index} >
				{(provided) => (
					<GroupPermissionWidget
						ref={provided.innerRef}
						id={id}
						content_type={content_type}
						name={name}
						key={`group-permission-widget-${index}`}
						drag_class={drag_class}
						provided={provided} />
				)}
			</Draggable>
		)
	});
	
	return (
		<Card className="half-width-dnd-container with-min-height with-vertical-scrolling">
			<Card.Header className='default-title'>{title}</Card.Header>
			<Card.Body ref={ref} {...provided.droppableProps}>
				{search_field_jsx}
				{draggable_jsx}
				{provided.placeholder}
			</Card.Body>
		</Card>
	)
})

export default PermissionDnDContainer;