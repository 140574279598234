import React, { useRef, useCallback, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation} from 'react-i18next';
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

const DeviceProviderForm = ({device_obj, event_key}) => {
	
	const form_ref = useRef(null);
	const select_ref = useRef(null);
	
	const dispatch = useDispatch()
	
	const {t} = useTranslation();
	
	const [validated, set_validated] = useState(false);
	
	const urls = useSelector(state => state.urls)
	const provider_configurations = useSelector(state => state.settings.provider_configurations)
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			let data = {
				"device_id": device_obj.id,
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['lorawan-provider-api:device-provider-configuration']();
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
				const {status} = payload.value
				if(status === 200) {
					const {data} = payload.value
					if(data.error) {
						dispatch(set_header_form_alert(true, "danger", data.error_message))
					} else {
						if(data.device_exists) {
							dispatch(set_header_form_alert(true, "success", t('Die Konfiguration wurde gespeichert und das Gerät existiert beim entsprechenden Provider.')))
						} else {
							dispatch(set_header_form_alert(true, "success", t('Die Konfiguration wurde gespeichert.')))
						}
					}
					dispatch(send({'type': 'initial.data.for.settings.change'}));
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				}
			})
		}
		set_validated(true);
	}, [device_obj, form_ref, t, dispatch, urls])
	
	let options_jsx = [
		<option key="no-provider-configuration" value={0}>{t('Keine Konfiguration zugeordnet')}</option>
	]
	for(const pc of provider_configurations) {
		options_jsx.push(
			<option key={`provider-configuration-option-${pc.id}`} value={pc.id}>{pc.label}</option>
		)
	}
	
	const select_default_value = device_obj.provider === null ? 0 : device_obj.provider.id
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('LoraWAN Provider Einstellung')}</Accordion.Header>
			<Accordion.Body>
				<Row>
					<Col>
						<Form ref={form_ref} validated={validated}>
							<FloatingLabel label={t('Provider Konfigurationen')}>
								<Form.Select 
									ref={select_ref}
									key={`user-${new Date().getTime()}`} 
									defaultValue={select_default_value} 
									name="device_provider_configuration_id"
									>
									{options_jsx}
								</Form.Select>
							</FloatingLabel>
						</Form>
					</Col>
				</Row>
				<ButtonToolbar className="mt-3">
					<ButtonGroup className="me-2">
						<Button variant="" className="primary-light-bg" onClick={handle_save_click}>{t('Speichern')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default DeviceProviderForm;

//onChange={change_user_select}