import React from 'react';
import { useTranslation} from 'react-i18next';
import Badge from 'react-bootstrap/Badge'
import {BellSlash} from 'react-bootstrap-icons'

const AlarmModeIndicator = () => {
	const {t} = useTranslation();
	return (
		<Badge bg='' className="danger-light-bg">
		<BellSlash /> <span>{t('Testmodus')}</span>
		</Badge>
	);
}

export default AlarmModeIndicator;
