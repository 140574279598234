import React, { useState, useEffect, useCallback, useRef } from 'react';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation} from 'react-i18next';

import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'

import DeviceHardwareConf24Form from '../DeviceHardwareConf24Form';
import DeviceHardwareConf12Form from '../DeviceHardwareConf12Form';

import {useFormError} from '../formHooks'

import {
	set_header_form_alert,
	async_do_simple_form_post,
	set_device_hardware_configuration
} from '../../../actions'

const DeviceHardwareConfWidget = ({device_obj, event_key, current_active_key}) => {
	const {t} = useTranslation();
	
	const dispatch = useDispatch()
	
	const config_sent_ref = useRef(false)
	
	const [, set_form_errors, get_error_state] = useFormError({})
	
	const device_download_queue_items = useSelector(state => state.settings.device_download_queue_items[device_obj.dev_eui])
	
	const urls = useSelector(state => state.urls)
	
	const [is_initialized, set_is_initialized] = useState(false)
	
	const initial_download_queue_load = useCallback(() => {
		dispatch(send({"type":"update.downlink.queue.request", 'json_content': {"dev_eui": device_obj.dev_eui}}));
		set_is_initialized(true)
	}, [device_obj.dev_eui, dispatch])
	
	const reload_download_queue = (e) => {
		dispatch(send({"type":"update.downlink.queue.request", 'json_content': {"dev_eui": device_obj.dev_eui}}));
	}
	
	const flush_download_queue_click = (e) => {
		config_sent_ref.current = false
		dispatch(send({"type":"flush.downlink.queue.request", 'json_content': {"dev_eui": device_obj.dev_eui}}));
	}
	
	useEffect(() => {
		if(current_active_key === event_key && !is_initialized) { // load data for configuration
			initial_download_queue_load();
		}
	}, [is_initialized, initial_download_queue_load, current_active_key, event_key]);
	
	const update_configuration = useCallback((form_data) => {
		let data = {
			"dev_type": device_obj.dev_type,
			"dev_id": device_obj.id,
			...form_data
		}
		console.log('update_configuration called, data: ', data)
		const url = urls['iot-api:device-hardware-configuration']();
		config_sent_ref.current = false
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert, es wird versucht die Nachricht an den Provider zu übergeben.')))
				// delete errors
				set_form_errors({})
				// update the configuration, do not do a initial data websocket message, that would be heavy overkill
				dispatch(set_device_hardware_configuration(data.dev_eui, data.device_configuration))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				set_form_errors(data.errors)
			}
			config_sent_ref.current = true
		})
	}, [device_obj.dev_type, device_obj.id, dispatch, set_form_errors, t, urls])
	
	let downlink_queue_widget_jsx = []
	let downlink_items_jsx = []
	let config_form_widget = []
	let form_config_jsx = [];
	
	if(device_download_queue_items !== undefined) {
		for(const item of device_download_queue_items.items) {
			downlink_items_jsx.push(
				<Card key={`downlink-item-${item.id}`} className="default-setttings-container">
					<Card.Header>
						<div className="default-title">
							ID: {item.id}, Port: {item.f_port}
						</div>
					</Card.Header>
				</Card>
			)
		}
	}
	
	if(device_obj.provider !== null) {
		if(device_obj.provider.downlink_queue_cmd_available) {
			let alert_jsx = []
			let button_toolbar_jsx = []
			let button_group_jsx = []
			if(device_download_queue_items === undefined || !device_download_queue_items.total_count) {
				if(device_download_queue_items !== undefined && device_download_queue_items.error) {
					alert_jsx.push(
						<Alert variant="danger" key="download-queue-error-alert">
							{t('Der Nachrichten Status konnte nicht abgefragt werden. Es besteht ein Problem mit dem Provider Server!')}
						</Alert>
					)
				} else {
					alert_jsx.push(
						<Alert variant="success" key="download-queue-empty-alert">
							{t('Es sind keine Nachrichten in der Download Queue.')}
						</Alert>
					)
				}
			} else {
				alert_jsx.push(
					<Alert variant="warning" key="download-queue-warning-alert">
						{t('Es sind bereits Nachrichten in der Download Warteschlange vorhanden. Diese müssen zuerst vom Gerät heruntergeladen werden. Es kann eine weile Dauern bis die gewünschte Konfiguration heruntergeladen und aktiv wird.')}
					</Alert>
				)
				button_group_jsx.push(
					<ButtonGroup className="me-2" key="flush-download-queue-button-group">
						<Button className="primary-light-bg" onClick={flush_download_queue_click}>{t('Warteschlange löschen')}</Button>
					</ButtonGroup>
				)
			}
			
			button_group_jsx.push(
				<ButtonGroup className="me-2" key="reload-download-queue-button-group">
					<Button className="primary-light-bg" onClick={reload_download_queue}>{t('Neu laden')}</Button>
				</ButtonGroup>
			)
			
			button_toolbar_jsx.push(
				<ButtonToolbar key="download-queue-flush-button-toolbar" className="mt-3">
					{button_group_jsx}
				</ButtonToolbar>
			)
			
			downlink_queue_widget_jsx.push(
				<Card className="default-setttings-container" key="device downlink-queue-widget">
					<Card.Header as="h5">
						{t('Konfigurations Download Warteschlange')}
					</Card.Header>
					<Card.Body>
						{alert_jsx}
						{downlink_items_jsx}
						{button_toolbar_jsx}
					</Card.Body>
				</Card>
			)
		} else {
			downlink_queue_widget_jsx.push(
				<Alert variant="warning" key="warn-about-no-cmd-queue-available">
					{t('Der Provider unterstützt keine Abfrage der Geräte Download Nachrichten Warteschlange.')}
				</Alert>
			)
		}
	
		let provider_error = device_download_queue_items === undefined ? false : device_download_queue_items.error
		
		if(device_obj.dev_type === '2.4' && device_obj.device_configuration !== null) {
			form_config_jsx.push(
				<DeviceHardwareConf24Form key={`configuration-form-${device_obj.id}-${device_obj.device_configuration.id}`} 
										  device_configuration={device_obj.device_configuration}
										  get_error_state={get_error_state} 
										  update_configuration={update_configuration}
										  provider_error={provider_error}
										  config_sent_ref={config_sent_ref} />
			)
			config_form_widget.push(
				<Card className="default-setttings-container" key="device-configuration-form-widget">
					<Card.Header as="h5">
						{t('Hardware Konfiguration')}
					</Card.Header>
					<Card.Body>
						<p className="mt-3 mb-4">
							{t('Beim speichern wird die Konfiguration an den Provider Server übergeben. Die Konfiguration wird erst auf dem Gerät gespeichert, wenn dieses die Downlink Nachricht abholt. Dies ist abhängig vom Interval des Gerätes.')}
						</p>
						{form_config_jsx}
					</Card.Body>
				</Card>
			)
		} else if(device_obj.dev_type === '1.2' && device_obj.device_configuration !== null) {
			form_config_jsx.push(
				<DeviceHardwareConf12Form key={`configuration-form-${device_obj.id}-${device_obj.device_configuration.id}`} 
										  device_configuration={device_obj.device_configuration}
										  get_error_state={get_error_state} 
										  update_configuration={update_configuration}
										  provider_error={provider_error}
										  config_sent_ref={config_sent_ref} />
			)
			config_form_widget.push(
				<Card className="default-setttings-container" key="device-configuration-form-widget">
					<Card.Header as="h5">
						{t('Hardware Konfiguration')}
					</Card.Header>
					<Card.Body>
						<p className="mt-3 mb-4">
							{t('Beim speichern wird die Konfiguration an den Provider Server übergeben. Die Konfiguration wird erst auf dem Gerät gespeichert, wenn dieses die Downlink Nachricht abholt. Dies ist abhängig vom Interval des Gerätes.')}
						</p>
						{form_config_jsx}
					</Card.Body>
				</Card>
			)
		}
	} else {
		config_form_widget.push(
			<p key="no-device-provider-error" className="mt-3">{t('Diesem Gerät ist kein Provider zugeordnet. Es kann daher nicht konfinguriert werden.')}</p>
		)
	}
	
	return (
		<>
			{downlink_queue_widget_jsx}
			{config_form_widget}
		</>
	)
}

export default DeviceHardwareConfWidget;

/*

		<Card className="default-setttings-container">
			<Card.Header as="h5">
				{t('Hardware Konfiguration')}
			</Card.Header>
			<Card.Body>
				<p>
					{t('Beim speichern wird die Konfiguration an den Provider Server übergeben. Die Konfiguration wird erst auf dem Gerät gespeichert, wenn dieses die Downlink Nachricht abholt. Dies ist abhängig vom Interval des Gerätes.')}
				</p>
				{form_config_jsx}
			</Card.Body>
		</Card>
*/