import React, { useState, useRef } from 'react';
import { useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Alert from 'react-bootstrap/Alert'

import InputFloatField from './fields/InputFloatField'

const DeviceHardwareConf12Form = ({device_configuration, 
								  get_error_state, 
								  update_configuration,
								  provider_error,
								  config_sent_ref}) => {
	console.log('device_configuration: ', device_configuration)
	const {t} = useTranslation();
	
	const form_ref = useRef(null);
	
	//const config_sent_ref = useRef(false)
	
	const [validated, set_validated] = useState(false);
	
	let default_value_props = {
		send_interval_min: { default_value: device_configuration.send_interval_min }
	}
	
	const update_hardware_configuration = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				id: device_configuration.id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			update_configuration(data)
		}
		set_validated(true);
	}
	
	let provider_error_jsx = []
	if(config_sent_ref.current) {
		if(provider_error) {
			provider_error_jsx.push(
				<Alert variant="danger" key="provider-error">{t('Es ist ein Problem beim Lora WAN Provider aufgetreten. Die Konfiguration wird nicht zum Gerät gesendet!')}</Alert>
			)
		} else {
			provider_error_jsx.push(
				<Alert variant="success" key="provider-success">{t('Die Konfiguration wurde erfolgreich dem Provider übergeben.')}</Alert>
			)
		}
	}
	
	return (
		<Form ref={form_ref} validated={validated}>
			<Container fluid>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Daten Sende Interval (in Min.)')} 
							field_name="send_interval_min" 
							field_type="number"
							key={`send_interval_min-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("send_interval_min")}
							{...default_value_props.send_interval_min} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Interval in Minuten. Mindestens 1 Minute.")}
						</small>
					</Col>
				</Row>
				{provider_error_jsx}
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button className="primary-light-bg" onClick={update_hardware_configuration} >{t('Konfiguration speichern und in die Download Warteschlange einfügen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
			</Container>
		</Form>
	)
}

export default DeviceHardwareConf12Form;
