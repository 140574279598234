import { createAsyncAction } from 'redux-promise-middleware-actions';
import axios from 'axios'
import {prepare_headers} from './utils/http'
import {IOT_APP_PREFIX, WEBSOCKET_PREFIX} from './Config'
import {
  WEBSOCKET_BROKEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_SEND,
  WEBSOCKET_OPEN,
} from '@giantmachines/redux-websocket';

// types
export const SET_URLS = `${IOT_APP_PREFIX}::SET_URLS`
export const SET_WEBSOCKET_URL = `${IOT_APP_PREFIX}::SET_WEBSOCKET_URL`
export const SET_AUTHENTICATION = `${IOT_APP_PREFIX}::SET_AUTHENTICATION`
export const SET_HEADER_FORM_ALERT = `${IOT_APP_PREFIX}::SET_HEADER_FORM_ALERT`
export const SET_LOADER_SHOW = `${IOT_APP_PREFIX}::SET_LOADER_SHOW`
export const SET_DATA_PREFETCH_LOADING = `${IOT_APP_PREFIX}::SET_DATA_PREFETCH_LOADING`
export const SET_DEVICE_HARDWARE_CONFIGURATION = `${IOT_APP_PREFIX}::SET_DEVICE_HARDWARE_CONFIGURATION`

// async actions for http
const IOT_DO_LOGIN = `${IOT_APP_PREFIX}::DO_LOGIN`
const IOT_DO_LOGOUT = `${IOT_APP_PREFIX}::DO_LOGOUT`
const IOT_DO_SIMPLE_FORM_POST = `${IOT_APP_PREFIX}::DO_SIMPLE_FORM_POST`
const IOT_DO_CSV_EXPORT = `${IOT_APP_PREFIX}::DO_CSV_EXPORT`

// websocket specific
export const IOT_WEBSOCKET_OPEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`
export const IOT_WEBSOCKET_MESSAGE = `${WEBSOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`
export const IOT_WEBSOCKET_SEND = `${WEBSOCKET_PREFIX}::${WEBSOCKET_SEND}`
export const IOT_WEBSOCKET_CLOSED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`
export const IOT_WEBSOCKET_BROKEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BROKEN}`

// functions

export const set_urls = (urls) => {
	return { type: SET_URLS, urls: urls };
}

export const set_websocket_url = (url) => {
	return { type: SET_WEBSOCKET_URL, url: url };
}

export const set_authentication = (auth_obj, error) => {
	return { 
		type: SET_AUTHENTICATION, 
		auth_obj,
		error: error 
	}
}

export const set_header_form_alert = (show, cls, message) => {
	return { 
		type: SET_HEADER_FORM_ALERT, 
		show: show, 
		cls: cls,
		message: message 
	}
}

export const set_loader_show = (show) => {
	//argument is true or false.
	//de- or increment the show counter
	return { 
		type: SET_LOADER_SHOW, 
		show: show
	}
}

export const set_data_prefetch_loading = (loading) => {
	return { 
		type: SET_DATA_PREFETCH_LOADING, 
		loading: loading
	}
}

export const set_device_hardware_configuration = (dev_eui, device_configuration) => {
	return {
		type: SET_DEVICE_HARDWARE_CONFIGURATION, 
		dev_eui: dev_eui,
		device_configuration: device_configuration
	}
}

// actions with promises / axios requests
export const async_do_login = createAsyncAction(IOT_DO_LOGIN, async (data, url) => {
	const request_options = prepare_headers()
	const response = await axios.post(url, data, request_options)
	return response;
});

export const async_do_logout = createAsyncAction(IOT_DO_LOGOUT, async (url) => {
	const request_options = prepare_headers()
	const response = await axios.post(url, {}, request_options)
	return response
});

export const async_do_simple_form_post = createAsyncAction(IOT_DO_SIMPLE_FORM_POST, async (data, url) => {
	const request_options = prepare_headers()
	const response = await axios.post(url, data, request_options)
	return response
});

export const async_do_http_csv_export = createAsyncAction(IOT_DO_CSV_EXPORT, async (data, url) => {
	const request_options = prepare_headers()
	request_options.headers.responseType = 'blob'
	const response = await axios.post(url, data, request_options)
	return response
});