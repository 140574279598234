import React, {useEffect, useLayoutEffect, useRef, useCallback} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Decimation
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import DeviceChartZoomButton from './device/DeviceChartZoomButton'

// registered plugins

const AfterRenderTracker = {
	id: 'after_render_tracker',
	do_reaction: true,
	afterRender: (chart, args, options) => {
		if(options.chart_after_render_callback !== undefined) { // only given on big charts
			options.chart_after_render_callback()
		}
	}
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  zoomPlugin,
  Decimation,
  AfterRenderTracker
);


// inline plugin
const DrawTraceLine = {
	id: 'draw-trace-line',
	x: null,
	line_shown: false,
    afterDraw: (chart, args, options) => {
		if(DrawTraceLine.line_shown) { // only if line is shown (mouse is in)
			let xScale = chart.scales.x
			let pixel_value = xScale.getValueForPixel(DrawTraceLine.x)
			if(pixel_value > chart.scales.x.min) { // only if over the area of the xscale
		        let yScale = chart.scales[chart.getDatasetMeta(0).yAxisID];
		        let lineX = DrawTraceLine.x
				chart.ctx.beginPath();
				chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max));
				chart.ctx.lineWidth = 1;
				chart.ctx.strokeStyle = '#F66';
				chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min));
				chart.ctx.stroke();
			}
		}
		return true;
    },
    afterEvent: (chart, args, options) => {
		if(args.event.type === 'mouseout') {
			DrawTraceLine.line_shown = false
		}
		if(args.event.type === 'mousemove') {
			DrawTraceLine.line_shown = true
			DrawTraceLine.x = args.event.x
		}
		chart.draw();
	}
	
}



/*
	https://react-chartjs-2.js.org/
	https://www.chartjs.org/docs/latest/
*/

const ResponsiveLineChartCanvas = ({ channel_id, 
									 chart_info, 
									 chart_type, 
									 device_obj,
									 chart_after_render_callback }) => {
	
	const chart_ref = useRef(null)
	const parent_ref = useRef(null)
	const plugins_ref = useRef([])
	
	const zoom_complete_callback = useCallback((chart) => {
		let chartjs = chart.chart
		chartjs.update()
		if(chartjs.isZoomedOrPanned()) {
			parent_ref.current.set_zoom_button_state(true)
		} else {
			parent_ref.current.set_zoom_button_state(false)
		}
	}, [])
	
	// anyway remove zoom button on rerender
	useEffect(() => {
		if(chart_type === 'big') {
			if(parent_ref.current !== null) {
				parent_ref.current.set_zoom_button_state(false)
			}
		}
	})
	
	// anyway reset zoom on rerender, be sure it is called before everything else
	useLayoutEffect(() => {
		if(chart_ref.current !== null) {
			chart_ref.current.resetZoom(chart_ref.current)
		}
	})
	
	let redraw = false
	if(chart_type === 'big') {
		redraw = false // was true 
		plugins_ref.current = [
			DrawTraceLine,
		]
			
		chart_info.options.plugins.after_render_tracker = { // set callback as option for the global plugin
			chart_after_render_callback: chart_after_render_callback,
		}
			
		// set the callback for zoom complete
		chart_info.options.plugins.zoom.zoom.onZoomComplete = zoom_complete_callback
	}
	
	return (
		<div className="chart-container">
			<DeviceChartZoomButton ref={parent_ref} chart_ref={chart_ref} />
			<Line
			   redraw={redraw} // sadly we need a redraw, else we get some wierd error from zoom in combination with device update
			   ref={chart_ref}
			   datasetIdKey={`${device_obj.eui}-${channel_id}`} 
			   plugins={plugins_ref.current} 
			   options={
				chart_info.options 
			 } data={{
				datasets: [...chart_info.datasets]
			}} />
		</div>
	)
}

export default ResponsiveLineChartCanvas;