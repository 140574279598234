import React, { useState, useRef } from 'react';
import { useTranslation} from 'react-i18next';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Alert from 'react-bootstrap/Alert'

import InputFloatField from './fields/InputFloatField'

const DeviceHardwareConf24Form = ({device_configuration, 
								  get_error_state, 
								  update_configuration,
								  provider_error,
								  config_sent_ref}) => {
									  
	const {t} = useTranslation();
	
	const form_ref = useRef(null);
	const night_mode_ref = useRef(null)
	const alarm_mode_ref = useRef(null)
	
	//const config_sent_ref = useRef(false)
	
	const [validated, set_validated] = useState(false);
	
	let default_value_props = {
		alarm_mode: { defaultChecked: device_configuration.alarm_mode },
		send_interval_min: { default_value: device_configuration.send_interval_min },
		alarm_delay_sec: { default_value: device_configuration.alarm_delay_sec },
		night_mode: { defaultChecked: device_configuration.night_mode },
		channel_alarm_ch_1: { default_value: device_configuration.channel_alarm_ch_1 },
		channel_alarm_night_ch_1: { default_value: device_configuration.channel_alarm_night_ch_1 },
		channel_alarm_ch_2: { default_value: device_configuration.channel_alarm_ch_2 },
		channel_alarm_night_ch_2: { default_value: device_configuration.channel_alarm_night_ch_2 },
		channel_alarm_ch_3: { default_value: device_configuration.channel_alarm_ch_3 },
		channel_alarm_night_ch_3: { default_value: device_configuration.channel_alarm_night_ch_3 },
		channel_alarm_ch_4: { default_value: device_configuration.channel_alarm_ch_4 },
		channel_alarm_night_ch_4: { default_value: device_configuration.channel_alarm_night_ch_4 },
	}
	
	const update_hardware_configuration = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				id: device_configuration.id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			if(night_mode_ref.current !== null) {
				data['night_mode'] = night_mode_ref.current.checked
			}
			if(alarm_mode_ref.current !== null) {
				data['alarm_mode'] = alarm_mode_ref.current.checked
			}
			update_configuration(data)
		}
		set_validated(true);
	}
	
	let provider_error_jsx = []
	if(config_sent_ref.current) {
		if(provider_error) {
			provider_error_jsx.push(
				<Alert variant="danger" key="provider-error">{t('Es ist ein Problem beim Lora WAN Provider aufgetreten. Die Konfiguration wird nicht zum Gerät gesendet!')}</Alert>
			)
		} else {
			provider_error_jsx.push(
				<Alert variant="success" key="provider-success">{t('Die Konfiguration wurde erfolgreich dem Provider übergeben.')}</Alert>
			)
		}
	}
	
	return (
		<Form ref={form_ref} validated={validated}>
			<Container fluid>
				<Row>
					<Col lg={true} className="mb-3">
						<Form.Check 
					        type="switch"
					        id="alarm_mode"
					        ref={alarm_mode_ref}
					        key={`alarm_mode-${device_configuration.id}-${new Date().getTime()}`} 
					        label={t('Alarmierung aktiv')}
					        {...default_value_props.alarm_mode}
					      />
					      <small className="form-text text-muted mb-3 d-inline-block">
						  {t("Aktiviert / Deaktiviert das auslösen aller Alarme auf dem Gerät.")}
						  </small>
					</Col>
					<Col lg={true} className="mb-3">
						<Form.Check 
					        type="switch"
					        id="night_mode"
					        ref={night_mode_ref}
					        key={`night_mode-${device_configuration.id}-${new Date().getTime()}`} 
					        label={t('Nachtmodus/Nachtabsenkung')}
					        {...default_value_props.night_mode}
					      />
					      <small className="form-text text-muted mb-3 d-inline-block">
						  {t("Aktiviert die Nachtabsenkung, und verwendet deren Alarmpegel.")}
						  </small>
					</Col>
				</Row>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Daten Sende Interval (in Min.)')} 
							field_name="send_interval_min" 
							field_type="number"
							key={`send_interval_min-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("send_interval_min")}
							{...default_value_props.send_interval_min} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Interval in Minuten. Mindestens 1 Minute.")}
						</small>
					</Col>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Verzögerung (in Sekunden)')} 
							field_name="alarm_delay_sec" 
							field_type="number"
							key={`alarm_delay_sec-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("alarm_delay_sec")}
							{...default_value_props.alarm_delay_sec} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Verzögerung in Sekunden bis der Alarm auf dem Gerät auslöst. Die Pegel Überschreitung muss so lange anliegen.")}
						</small>
					</Col>
				</Row>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Kanal 1')} 
							field_name="channel_alarm_ch_1" 
							field_type="number"
							key={`channel_alarm_ch_1-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_ch_1")}
							{...default_value_props.channel_alarm_ch_1} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Nacht Kanal 1')} 
							field_name="channel_alarm_night_ch_1" 
							field_type="number"
							key={`channel_alarm_night_ch_1-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_night_ch_1")}
							{...default_value_props.channel_alarm_night_ch_1} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
				</Row>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Kanal 2')} 
							field_name="channel_alarm_ch_2" 
							field_type="number"
							key={`channel_alarm_ch_2-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_ch_2")}
							{...default_value_props.channel_alarm_ch_2} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Nacht Kanal 2')} 
							field_name="channel_alarm_night_ch_2" 
							field_type="number"
							key={`channel_alarm_night_ch_2-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_night_ch_2")}
							{...default_value_props.channel_alarm_night_ch_2} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
				</Row>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Kanal 3')} 
							field_name="channel_alarm_ch_3" 
							field_type="number"
							key={`channel_alarm_ch_3-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_ch_3")}
							{...default_value_props.channel_alarm_ch_3} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Nacht Kanal 3')} 
							field_name="channel_alarm_night_ch_3" 
							field_type="number"
							key={`channel_alarm_night_ch_3-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_night_ch_3")}
							{...default_value_props.channel_alarm_night_ch_3} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
				</Row>
				<Row>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Kanal 4')} 
							field_name="channel_alarm_ch_4" 
							field_type="number"
							key={`channel_alarm_ch_4-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_ch_4")}
							{...default_value_props.channel_alarm_ch_4} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
					<Col lg={true}>
						<InputFloatField 
							is_required={true}
							label={t('Alarm Grenzwert Nacht Kanal 4')} 
							field_name="channel_alarm_night_ch_4" 
							field_type="number"
							key={`channel_alarm_night_ch_4-${device_configuration.id}-${new Date().getTime()}`}
							errors={get_error_state("channel_alarm_night_ch_4")}
							{...default_value_props.channel_alarm_night_ch_4} />
						<small className="form-text text-muted mb-3 d-inline-block">
						  {t("Der Wert muss zwischen -327.00 und 327.00 PA liegen.")}
						</small>
					</Col>
				</Row>
				{provider_error_jsx}
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button className="primary-light-bg" onClick={update_hardware_configuration} >{t('Konfiguration speichern und in die Download Warteschlange einfügen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
			</Container>
		</Form>
	)
}

export default DeviceHardwareConf24Form;
