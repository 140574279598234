import React, { useRef, useCallback, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';

import {
	set_header_form_alert,
	async_do_simple_form_post 
} from '../../actions'

import InputFloatField from './fields/InputFloatField'

import DeviceDataDeletePartForm from './DeviceDataDeletePartForm'
import DeviceChannelAlarmForm from './DeviceChannelAlarmForm'
import SetAlarmNotificationForm from './SetAlarmNotificationForm'
import DeviceHardwareConfForm from './DeviceHardwareConfForm';
import DeviceProviderConfForm from './DeviceProviderConfForm';

import {usePermission} from '../../hooks'

const DeviceSettingsForm = ({ device_obj }) => {
	const {t} = useTranslation();
	
	const has_perm = usePermission()
	
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	
	const [validated, set_validated] = useState(false);
	
	const [current_active_key, set_current_active_key] = useState("0") // keep track of the current key, to initialize some forms if the accordion opens
	
	const form_ref = useRef(null);
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			let data = {
				"id": device_obj.id,
				"dev_eui": device_obj.dev_eui
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['iot-api:device-settings']();
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
				const {status} = payload.value
				if(status === 200) {
					dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
					dispatch(send({'type': 'initial.data.for.settings.change'}));
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				}
			})
		}
		set_validated(true);
	}, [device_obj, form_ref, t, dispatch, urls])
	
	let device_channel_alarm_form_jsx = []
	if(has_perm('iot.change_alarm') && 
	   has_perm('iot.delete_alarm') && 
	   has_perm('iot.create_alarm')) {
		device_channel_alarm_form_jsx = [
			<DeviceChannelAlarmForm key="device-alarm-settings-form" device_obj={device_obj} event_key={3} current_active_key={current_active_key} />
		]
	}
	
	let device_hardware_configuration_form = []
	var hardware_conf_permission_given = false
	if(device_obj.dev_type === '2.4' || device_obj.dev_type === '2.2') {
		if(has_perm('iot.change_deviceconfiguration12') && 
		   has_perm('iot.delete_deviceconfiguration12') && 
		   has_perm('iot.create_deviceconfiguration12')) {
			hardware_conf_permission_given = true
		}
	} else {
		if(has_perm('iot.change_deviceconfiguration24') && 
		   has_perm('iot.delete_deviceconfiguration24') && 
		   has_perm('iot.create_deviceconfiguration24')) {
			hardware_conf_permission_given = true
		}
	}
	if(hardware_conf_permission_given) {
		device_hardware_configuration_form = [
			<DeviceHardwareConfForm key="device-hardware-conf-form" device_obj={device_obj} event_key={1} current_active_key={current_active_key} />
		]
	}
	
	let device_provider_conf_form_jsx = []
	if(has_perm('lorawan_provider.change_providerconfiguration')) {
		device_provider_conf_form_jsx.push(
			<DeviceProviderConfForm key="deivce-lorawan-provider-settings" device_obj={device_obj} event_key={4} />
		)
	}
	
	const on_accordion_select = (event_key, event) => {
		set_current_active_key(event_key)
	}
	
	return (
		<Accordion defaultActiveKey="0" onSelect={on_accordion_select}>
			<Accordion.Item eventKey="0">
				<Accordion.Header>{t('Generelle Einstellungen')}</Accordion.Header>
				<Accordion.Body>
					<Row>
						<Col>
							<Form ref={form_ref} validated={validated}>
								<InputFloatField 
									label={t('Lokaler Geräte Name')}
									field_name="dev_name" 
									field_type="text" 
									default_value={device_obj.dev_name} />
							</Form>
						</Col>
					</Row>
					<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
				</Accordion.Body>
			</Accordion.Item>
			{device_provider_conf_form_jsx}
			{device_hardware_configuration_form}
			<SetAlarmNotificationForm obj={device_obj} obj_type='device' event_key={2} />
			{device_channel_alarm_form_jsx}
			<DeviceDataDeletePartForm device_obj={device_obj} event_key={5} />
		</Accordion>
	)
}

export default DeviceSettingsForm;