import React from 'react';
import BatteryIndicator from '../indicators/BatteryIndicator';
import AlarmIndicator from '../indicators/AlarmIndicator';
import DeviceTypeIndicator from '../indicators/DeviceTypeIndicator'
import DataPointsIndicator from '../indicators/DataPointsIndicator'
import NightModeIndicator from '../indicators/NightModeIndicator';
import AlarmModeIndicator from '../indicators/AlarmModeIndicator';

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const DeviceTitleContainer = ({ device_obj, triggered_alarm_count, triggered_alarm_names, small_title }) => {
	if(small_title === undefined) {
		small_title = false;
	}
	let title = device_obj['device_title']
	if(device_obj['device_title'] !== device_obj['dev_eui']) {
		title += ` (${device_obj['dev_eui']})`;
	}
	let battery_indicator_jsx = []
	if(device_obj['battery_level'] !== null) {
		battery_indicator_jsx = [
			<BatteryIndicator key={`battery-indicator-${device_obj['dev_eui']}`} battery_level={device_obj['battery_level']} />
		]
	}
	let alarm_indicator_jsx = []
	if(triggered_alarm_count !== null && triggered_alarm_names !== null) {
		alarm_indicator_jsx = [
			<AlarmIndicator key={`alarm-indicator-${device_obj['dev_eui']}`} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} device_obj={device_obj} />
		]
	}
	
	let night_mode_indicator_jsx = []
	if(device_obj.dev_type === '2.4' || device_obj.dev_type === '2.2') {
		if(device_obj.device_configuration !== null && device_obj.device_configuration.night_mode) {
			night_mode_indicator_jsx.push(
				<NightModeIndicator key={`night-mode-indicator-${device_obj['dev_eui']}`}/>
			)
		}
	}
	
	let alarm_mode_indicator_jsx = []
	if(device_obj.dev_type === '2.4' || device_obj.dev_type === '2.2') {
		if(device_obj.device_configuration !== null && !device_obj.device_configuration.alarm_mode) {
			alarm_mode_indicator_jsx.push(
				<AlarmModeIndicator key={`alarm-mode-indicator-${device_obj['dev_eui']}`}/>
			)
		}
	}
	
	let title_cls = 'default-title'
	if(small_title) {
		title_cls += ' small'
	}
	return (
		<Card.Header as="h5">
			<Container fluid>
  				<Row>
    				<Col className={title_cls}>{title}</Col>
					<Col className="indicator-outer-container">
						<Row className="align-items-center">
							<Col className="align-right">
								{alarm_mode_indicator_jsx}
							</Col>
							<Col className="align-right">
								{night_mode_indicator_jsx}
							</Col>
							<Col className="align-right">
								{alarm_indicator_jsx}
							</Col>
							<Col className="align-right">
								{battery_indicator_jsx}
							</Col>
							<Col className="align-right">
								<DeviceTypeIndicator device_type={device_obj['dev_type']} />
							</Col>
							<Col className="align-right">
								<DataPointsIndicator total_datapoints={device_obj.total_datapoints} />
							</Col>
						</Row>
					</Col>
  				</Row>
			</Container>
		</Card.Header>
	)
}

export default DeviceTitleContainer;