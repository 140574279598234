import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';

import DeviceHardwareConfWidget from './widgets/DeviceHardwareConfWidget';

const DeviceHardwareConfForm = ({device_obj, event_key, current_active_key}) => {
	const {t} = useTranslation();
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Geräte Hardware Konfiguration')}</Accordion.Header>
			<Accordion.Body>
				<DeviceHardwareConfWidget device_obj={device_obj} event_key={event_key} current_active_key={current_active_key} />
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default DeviceHardwareConfForm;